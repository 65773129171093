import { createRouter, createWebHistory } from 'vue-router'
import { ftWebApi, userInfo, loading, alert, ftRoute, paginationInfo, isNativeApp, deviceOs, notiTypeId, deleteLoginTokenToApp, appVersionChecking, appVersionInfo, appVersionUpdateAlert, removeChatDealer } from "/src/assets/js/common"
import { appStoreLInk } from '/src/assets/js/model'
/**
 * 画面のタイトル
 */
const title = "Fcloud株式会社エフティグループ"
/**
 * 画面のディスクリプション
 */
const description = {
    // コスト削減ページ
    costReduction: "日々オフィスやお仕事で使うものや人的コスト、毎月の固定費を削減！",
    // デジタル変革ページ
    dx: "デジタル技術を用いて、既存の生活やビジネスをさらに効率的・コスト低減させるサービスを紹介",
    // セキュリティページ
    security: "コンピュータウイルスやハッキングなどの脅威からコンピュータネットワークを効率的かつ包括的に保護",
    // SUPPORTページ
    support: "お困りの際に電話で相談できる窓口をご用意必要に応じてIT機器の導入支援もできるサービスです。訪問サポートや遠隔サポートもご用意しておりますので、電話だけでは不安な方も安心です。",
}

// Route情報
const routes = [
    // サイト
    { path:"/", name:"top", component: () => import ("/src/templates/Site.vue"), meta: { title: title, template: 'site' } },
    { path:"/about", name:"about", component: () => import ("/src/templates/Site.vue"), meta: { title:`About | ${title}`, template: 'site' } },
    { path:"/cost-reduction", name:"cost-reduction", component: () => import ("/src/templates/Site.vue"), meta: { title:`コスト削減 | ${title}`, description: description.costReduction, template: 'site' } },
    { path:"/dx", name:"dx", component: () => import ("/src/templates/Site.vue"), meta: { title:`デジタル変革 | ${title}`, description: description.dx, template: 'site' } },
    { path:"/help", name:"help", component: () => import ("/src/templates/Site.vue"), meta: { title:`よくある質問 | ${title}`, template: 'site' } },
    { path:"/login", name:"login", component: () => import ("/src/templates/Site.vue"), meta: { title:`ログイン | ${title}`, template: 'site' } },
    { path:"/noti", name:"noti", component: () => import ("/src/templates/Site.vue"), meta: { title:`お知らせ一覧 | ${title}`, template: 'site' } },
    { path:"/noti/detail", name:"noti-detail", component: () => import ("/src/templates/Site.vue"), meta: { title:`お知らせ詳細 | ${title}`, template: 'site' } },
    { path:"/register", name:"register", component: () => import ("/src/templates/Site.vue"), meta: { title:`新規登録 | ${title}`, template: 'site' } },
    { path:"/security", name:"security", component: () => import ("/src/templates/Site.vue"), meta: { title:`セキュリティ | ${title}`, description: description.security, template: 'site' } },
    { path:"/services", name:"services", component: () => import ("/src/templates/Site.vue"), meta: { title:`セキュリティ | ${title}`, description: description.security, template: 'site' } },
    { path:"/services/cost-reduction", name:"s-cost-reduction", component: () => import ("/src/templates/Site.vue"), meta: { title:`コスト削減 | ${title}`, description: description.costReduction, template: 'site' } },
    { path:"/services/dx", name:"s-dx", component: () => import ("/src/templates/Site.vue"), meta: { title:`デジタル変革 | ${title}`, description: description.dx, template: 'site' } },
    { path:"/services/security", name:"s-security", component: () => import ("/src/templates/Site.vue"), meta: { title:`セキュリティ | ${title}`, description: description.security, template: 'site' } },
    { path:"/support", name:"support", component: () => import ("/src/templates/Site.vue"), meta: { title:`サポート | ${title}`, description: description.support, template: 'site' } },
    { path:"/rd/app", name:"rd-app", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`アプリ | ${title}`, description: description.support, template: 'mypage' } },
    // マイページ
    { path:"/mypage", name:"mp-top", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`マイページトップ | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/add-employee", name:"mp-add-employee", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`従業員追加 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/add-employee/register", name:"mp-employee-register", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`従業員登録 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/contract-related", name:"mp-contract-related", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`ご契約内容 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/company-info", name:"mp-company-info", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`会社情報 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/help", name:"mp-help", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`よくある質問 | ${title}`, template: 'mypage', authorize: 'require'}},
    { path:"/mypage/member", name:"mp-member", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`会員証 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/noti", name:"mp-noti", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`お知らせ一覧 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/noti/detail", name:"mp-noti-detail", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`お知らせ詳細 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/profile", name:"mp-profile", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`会員情報 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/profile-edit", name:"mp-profile-edit", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`会員情報 | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/teikei", name:"mp-teikei", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`提携サービス | ${title}`, template: 'mypage', authorize: 'require'} },
    { path:"/mypage/tokuten", name:"mp-tokuten", component: ()=> import ("/src/templates/MyPage.vue"), meta: { title:`特典付きサービス | ${title}`, template: 'mypage', authorize: 'require'} },

    // パスワード再設定
    { path:"/login/reset-password", name:"reset-password", component: () => import ("/src/templates/Form.vue"), meta: { title:`パスワード再設定 | ${title}`, template: 'form' } },
    { path:"/login/confirm-password", name:"confirm-password", component: () => import ("/src/templates/Form.vue"), meta: { title:`パスワード再設定 | ${title}`, template: 'form', tokenValid: 'require' } },
    { path:"/mypage/first-login", name:"first-login", component: () => import ("/src/templates/Form.vue"), meta: { title:`初回認証 | ${title}`, template: 'form'} },

    // Page Not Found
    { path: "/:pathMatch(.*)*", name: 'pagenotfound', component: () => import ("/src/templates/PageNotFound.vue"), meta: { title: title, template: 'site' }},
    
    { path: "/howtoupdate", name: 'howtoupdate', component: () => import ("/src/templates/HowToUpdate.vue"), meta: { title: title, template: 'HowToUpdate' }},
    
]

// VueRouter作成
const router = createRouter({
    history : createWebHistory(),
    routes,
    async scrollBehavior(to, from, savedPosition) {
        if(to.hash){
            console.log("to.hash ",to.hash)
            return { el: to.hash, top: 80 }
        }
        return { top: 0 }
    },
})

// VueRouterで画面遷移を始まる前には下記の色々な設定と判断が行う
router.beforeEach( async (to, from) => {
    
   
    removeChatDealer()

    // 利用しているDeviceのOSを貰う
    deviceOs.value = getDeviceOs()
    
    // 「アプリストアへ画面遷移する」リンクが来る場合はDeviceのOSによってURLに遷移する
    if(to.name == "rd-app"){
        if(isNativeApp.value){
            window.location.href = appStoreLInk[deviceOs.value]
        }else{
            window.open(appStoreLInk[deviceOs.value], '_blank','noreferrer')
        }
        if(from.name){
            return { name:  from.name }
        }else{
            return { name:  "mp-top" }
        }
    }

    // アプリバージョン確認 *アプリのみ
    if(to.name !== "top"){
        appVersionConfirm()
    }

    
    // 画面遷移する時、遷移先と遷移元の情報設定
    // 遷移先
    ftRoute.to = to
    // 遷移元
    ftRoute.from = from

    // アラートコンポネントの情報をリセット
    if(!alert.forApp && !ftRoute.rdPage){
        for (const key in alert) {
            delete alert[key];
        }
    }
    
    // ドキュメントのタイトル設定
    document.title = to.meta.title
    // デスクリプションあるページの設定（＊/index.phpで設定したデスクリプションないページはデフォルトのデスクリプション）
    if(to.meta.description){
        const desc = document.getElementsByTagName("meta")["description"]
        desc.content = to.meta.description
    }

    // トークインがあるリンクの場合はトークインが無効リンクがどうか確認して画面遷移（例：リセットパスワードURL）
    if(to.meta.tokenValid == "require"){
        const data = {resetToken: to.query.token}
        const retData = await ftWebApi.post('/resetPasswordTokenValid', data );
        if(!retData.data){
            window.alert(`${window.location.href}\n無効なリンクです！\nもう一度試してみてください。ログインページに戻ります。`);
            return { name: "login" }
        }
    }

    // 遷移元と遷移先のページによってお知らせのページネーション情報リセット
    if(to.name){
        if(
            // サイトの「トップページとお知らせ一覧ページ」へ遷移する場合は遷移元はサイトのお知らせ詳細ページ以外ならページネーション情報をリセット
            ( ["top","noti"].includes(to.name) && from.name != "noti-detail" ) ||
            // マイページの「お知らせ一覧ページ」へ遷移する場合は遷移元はマイページのお知らせ詳細ページ以外ならページネーション情報をリセット
            ( ["mp-noti"].includes(to.name) && from.name != "mp-noti-detail" )
        ){
            // ページネーション情報リセット
            for (const key in paginationInfo) {
                delete paginationInfo[key]
            }
            // お知らせタイプリセット
            notiTypeId.value = 0
        }
    }

    // 「ログインチェック」
    // 「ログインしたユーザー情報貰う」
    if(!userInfo.value.id){
        loading.loginCheck = true
        loading.note = "ログイン認証中"
    }
    const retData = await ftWebApi.get(`user/info`);
    if(!userInfo.value.id){
        delete loading.loginCheck
        delete loading.note
    }

    // ログインしてない場合ログイン画面に遷移
    if(retData.data.status == "ng"){
        // ログインしたユーザー情報リセット
        userInfo.value = ""
        // 行先画面はログイン必須の画面であればログインページが表示する
        if(to.meta && to.meta.authorize == "require"){
            // 「ログインしてください」アラート出る
            if(!(isNativeApp.value && to.name == 'mp-top' && !from.name)){
                ftRoute.backBtn = false
                alert.status = true
                alert.message = "ログインしてください。"
                alert.okBtn = true
            }
            // ログイン画面でログイン成功した後行先画面にリダイレクトする為に行きたかった画面を保存する
            console.log("Redirect ", to)
            ftRoute.rdPage = to
            if(isNativeApp.value){
                deleteLoginTokenToApp[deviceOs.value]()
            }
            return { name: "login" }
        }
    }

    // ログインした場合
    if(retData.data.status == "ok"){
        delete ftRoute.rdPage
        // リセットしたユーザー情報変数にログインユーザー情報を入れる
        userInfo.value = retData.data.result
        // アクセスしたページはログインページの場合、既にログインした状態なので、マイページのトップページへ遷移
        if(to.name == "login"){
            return { name: "mp-top" }
        }
        if(to.meta.authorize == "require" && userInfo.value.is_first_login){
            return { name: "first-login" }
        }
    }
    ftRoute.backBtn = from && to && from.name && !['top', 'mp-top', 'login', 'register'].includes(to.name)
})

export default router


/**
 * 利用しているDeviceのOSを貰う（アプリとブラウザー）
 * 
 * @returns {string} ディバイスのOSを貰う
 */
const getDeviceOs = () => {
    // デフォルトは「android」に設定
    let os = "android"
    // Nativeアプリの場合DeviceのOS貰う
    if(isNativeApp.value){
        if(window.ftGroupAppJsBridge){
            os = "android"
        }
        if(window.webkit && window.webkit.messageHandlers){
            os = "ios"
        }
    }
    // Browserの場合DeviceのOS貰う
    else{
        if(navigator.userAgent.match(/(iPad|iPhone|iPod)/i)){
            os = "ios"
        }
        const ua = navigator.userAgent.toLowerCase();
        if(ua.indexOf("android") > -1){
            os = "android"
        }
    }
    return os
}

/**
 * @summary アプリバージョン確認 
 * @description 利用しているディバイスは下記の状態なら、バージョン確認の処理を行う
    - アプリなら、
    - バージョンチェックが完了してない
    - バンジョンチェック完了したけど、アプリ更新がまだ必須の場合
 */
const appVersionConfirm = () => {
    // 上記の@descriptionの状態であらば
    if(isNativeApp.value && !appVersionInfo.versionCheckFinished && !appVersionInfo.updateRequired){
        try {
            // iOSアプリの場合
            if(deviceOs.value == "ios"){
                // 下記のFuncitonはiOSアプリのウェブビューでバージョンの確認を行って、
                // アプリのウェブビューからevaluateする「appVersionCheckingResult」Funcitonを引き続き行う
                // *「appVersionCheckingResult」Funcitonは「/src/main.js」にある
                appVersionChecking.ios()
            }
            // Androidアプリの場合
            if(deviceOs.value == "android"){
                // 下記のFuncitonはAndroidアプリのウェブビューでバージョンの確認を行って、アプリ更新要るならtrue、アプリ更新要らないならfalse戻る 
                const require = appVersionChecking.android()
                appVersionInfo.updateRequired = require
                appVersionInfo.versionCheckFinished = true
                if(require){
                    // アプリ更新要るので、アプリ更新アラート出る
                    appVersionUpdateAlert()
                }
            }
        } catch (error) {
            // アプリのバージョン確認に何かエラーがある場合は、アプリ更新アラート出る
            appVersionInfo.appStatus = "old"
            appVersionUpdateAlert()
        }
    }
}